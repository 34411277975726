import { FaChevronRight } from 'react-icons/fa';
import { Link } from "react-router-dom"

function Header() {
    return (
        <>
        <header className="center">
            <h1 className="logo" alt=''>Dope<span className="logo-color">Setups</span></h1>
        </header>
            </>
    )
}

export default Header
